<template>
  <Main>
    <sdCards headless class="card">
      <a-skeleton active :paragraph="{ rows: 4 }" v-if="isLoading" />
      <div id="webterminal" style="width: 100%; height: 800px" v-show="!isLoading"></div>
    </sdCards>
  </Main>
</template>

<script>
import { onMounted, ref } from 'vue';
import { Main } from '../styled';

const Terminal = {
  name: 'Terminal',
  components: {
    Main,
  },
  setup() {
    const isLoading = ref(true);

    onMounted(() => {
      let script = ['https://metatraderweb.app/trade/widget.js'];
      let tag = document.createElement('script');
      tag.setAttribute('src', script);
      tag.setAttribute('type', 'text/javascript');
      document.head.appendChild(tag);

      setTimeout(() => {
        let tag_sec = document.createElement('script');
        tag_sec.innerHTML = `new MetaTraderWebTerminal('webterminal', {
        version: 5,
        servers: ['HubStation-Live', 'HubStation-Demo'],
        server: 'HubStation-Demo',
        utmSource: 'www.hubstation.tech',
        startMode: 'create_demo',
        language: 'en',
				startMode: "no_autologin",
        colorScheme: 'black_on_white',
				demoAllServers: false,
      });`;
        tag_sec.setAttribute('type', 'text/javascript');
        document.head.appendChild(tag_sec);
        isLoading.value = false;
      }, 1000);
    });

    return {
      isLoading,
    };
  },
};
export default Terminal;
</script>

<style>
.card {
  margin-top: 50px;
}
</style>
